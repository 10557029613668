body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.img-background-login {
    display: flex;
    position: relative;
    align-items: center;
    background-size: contain, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center;
}


.card-interno {
    cursor: pointer;
    width: 15rem;
    transition: 0.3s;
}

.card-interno:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}

.card-home {
    cursor: pointer;
    width: 7rem;
    transition: 0.3s;
}

.card-home:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}

.card-solicitacao {
    transition: 0.3s;
}

.card-solicitacao:hover {
    -webkit-transform: scale(1.10);
            transform: scale(1.10);
}

.botao-padrao {
    margin-right: 10;
    border-color: '#2980B9';
    color: '#2980B9';
    background-color: transparent;
}

.icone-nav {
    margin-right: 10px;
}

.barra-dash {
    color: white;
    background-color: #2980B9;

}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
    height: 90px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.navbar-light .navbar-nav .nav-link {
    color: #E30613;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: white;
    text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #E30613;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: #E30613;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: white;
    background-color: #E30613;
}

.navbar-toggler {
    background-color: white;
}

.navbar-collapse {
    flex-grow: 0;
    background-color: white;
    z-index: 10;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    background-color: white;
}

.item-branco {
    color: white;
}

.titulo-dash {
    margin-top: 5px;
    margin-bottom: -10px;
    color: white;
    cursor: pointer;
}

.titulo-dash p {
    color: white;
}

.pagina {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    background-color: gainsboro;
}

.modal-75 {
    min-width: 75%;
}

.modal-50 {
    min-width: 50%;
}

.box-login {
    width: 400px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
}

.box-login-titulo {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 40%;
}

.box-login-titulo h1,
h2 {
    color: #2980B9;
    text-align: center;
}

.box-login-inputs {
    width: 80%;
    height: 55%;
}

.box-botao-acessar {
    display: flex;
    justify-content: flex-end;
}

.centraliza {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centraliza-full {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.centraliza-img-banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centraliza-img-banner img {
    width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.link-banner {
    font-size: 12px;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
}

.descritivo-dimensoes-banner {
    font-size: 12px;
    margin-left: 5px;
    color: gray
}

.principal-sem-fundo {
    width: 100%;
    margin: 0 auto;
    padding: 0 0;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.principal-topo {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 0;
    background-color: gainsboro;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: row;
}

.conteudo {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 0;
    /* background-color: gainsboro; */
}

.topo {
    background-color: gainsboro;
    width: 100%;
    height: 220px;
    padding-top: 10px;
}

.logo img {
    /* background-image: url("./assets/logo.png"); */
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    width: 270px;
    height: 190px;
    padding-top: 10px;
    margin-top: -20px;
    justify-content: center;
    align-items: center;
    background-color: gainsboro;
}

.info-topo {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: gainsboro;
}

.info-dados {
    width: 60%;
    height: 150px;
    padding-bottom: 20px;
    border: gainsboro solid 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: gainsboro;
    flex-wrap: wrap;
    cursor: pointer;
    padding-top: 30px;
}

.info-dados h1 {
    background-color: gainsboro;
    color: #37B725;
}

.info-dados img {
    background-color: gainsboro;
}

.info-dados a {
    text-decoration: none;
}

.texto {
    margin-top: 120px;
    text-align: center;
    /* border: 4px solid green */
}

.texto-cinza {
    color: grey;
    font-size: 12px;
}

.texto a {
    font-size: 19px;
    text-decoration: none;
    color: #2980B9;
}

.box-voltar-site {
    display: flex;
    width: 400px;
    height: 60px;
    justify-content: center;
    align-items: center;
    color: #2980B9;
}

.box-voltar-site p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.menu {
    height: 40px;
    background-color: #2E3092;
    width: 100%;
    margin-top: -30px;
    display: flex;
    direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.menu ul {
    list-style: none;
    background-color: #2E3092;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.menu li {
    display: inline;
    float: left;
    padding: 0px;
    margin: 0;
    width: 150px;
    text-align: center;
    background-color: #2E3092;
    color: #FDB910;
    font-weight: bold;
    /* text-transform: uppercase; */
    font-size: 16px;
}

.menu :hover {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: bold;
}

.slides {
    margin-top: -10px;
    z-index: -2;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.desconhecido {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.desconhecido h1 {
    font-size: 44px;
}

.linha {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 20px;
    width: 100%;
    height: 300px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.linha-sem-espaco {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.linha-form {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    /* border: green solid 2px; */
}

.linha-form input {
    width: 100%;
}

.linha-sem-espaco-direita {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;
}

.box-newsletter {
    margin-top: 30px;
    background-color: white;
    height: 200px;
    width: 50%;
    /* border: #2980B9 solid 2px; */
}

.box-3c {
    margin-top: 30px;
    background-color: white;
    height: 200px;
    width: 30%;
    margin-right: 10px;
    margin-left: 10px;
    /* border: #    215497 solid 2px; */
}

.box-3c h3 {
    color: #2980B9;
    margin-bottom: 20px;
}

.box-3c p {
    margin-top: 30px;
    text-align: justify;
    margin-right: 20px;
}

.box-1c {
    margin-top: 30px;
    background-color: white;
    width: 100%;
    margin-bottom: 80px;
    margin-right: 10px;
    margin-left: 10px;
    /* border: #2980B9 solid 2px; */
}

.box-1c h3 {
    color: #2980B9;
    margin-bottom: 20px;
}

.box-1c a {
    text-decoration: none;
}

.box-1c p {
    margin-top: 30px;
    text-align: justify;
    margin-right: 20px;
}

.box-newsletter h3 {
    color: #2980B9;
    margin-left: 10px;
    margin-bottom: 20px;
}

.box-newsletter input {
    height: 40px;
    margin-left: 10px;
    padding-left: 10px;
    margin: 10px;
}

.box-newsletter-full input {
    height: 40px;
    margin-left: 10px;
    padding-left: 10px;
    margin: 10px;
}

.linha-box {
    margin-left: 10px;
    width: 80%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.linha-box-news {
    padding: 5px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.linha-box-admin {
    margin-top: 15px;
    width: 80%;
    height: 50px;
    /* border: olivedrab solid 2px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.linha-box-admin a {
    text-decoration: none;
}

.linha-box-admin a:hover {
    text-decoration: underline;
}

ul {
    padding-left: 0;
}

ol {
    padding-left: 0;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
}

#botao-cadastrar-news {
    height: 30px;
    width: 120px;
    background-color: #FDB910;
    color: #2980B9;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}

#lista-servicos {
    text-decoration: none;
    list-style: none;
    margin-left: 10px;
    font-size: 18px;
}

.item-servico {
    padding-bottom: 10px;
    margin-left: 15px;
}

#lista-servicos li:before {
    content: '\2713      ';
}

#footer {
    background-color: gainsboro;
    color: #2E3092;
    height: 200px;
    width: 100%;
    box-shadow: 2px -3px 2px 1px rgba(0, 0, 0, 0.2);
}

.box-footer {
    margin-top: 30px;
    padding-top: 10px;
    width: 40%;
    padding-left: 10px;
}

.box-footer h3 {
    color: #2E3092;
    margin-bottom: 15px;
}

.lista-footer {
    text-decoration: none;
    list-style: none;
    font-size: 18px;
}

.linha-footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #cdcbcb;
}

.linha-footer-box {
    width: 100%;
    justify-content: center;
    background-color: gainsboro;
    padding-bottom: 40px;
}

.conteudo-linha-footer {
    margin-top: 20px;
    width: 100%;
    max-width: 960px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    color: black;
    padding-left: 20px;
}

.item-footer {
    padding-bottom: 10px;
}

.lista-footer a {
    text-decoration: none;
    color: black;
}

.lista-footer a:hover {
    text-decoration: underline;
}

.conteudo-footer {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 0;
    display: flex;
    justify-content: space-around;
    background-color: gainsboro;
}

.linha-footer h6 {
    color: #413f3f;
    font-size: 10px;
    margin-right: 30px;
}

.linha-footer a {
    text-decoration: none;
    color: #413f3f;
    display: flex;
    align-items: flex-end;
    padding-bottom: 30px;
}

.linha-footer a:hover {
    text-decoration: underline;
    color: #413f3f;
}

.pasta-cv {
    min-width: '90%';
    min-height: 40px;
    background-color: 'red';
}

#conteudo-admin {
    display: flex;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #FDB910;
}

#conteudo-rh-login {
    display: flex;
    position: absolute;
    min-width: 100%;
    justify-content: center;
    align-items: center;
}

#tela-login {
    background-color: gainsboro;
    width: 450px;
    height: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

#tela-login-rh {
    width: 450px;
    height: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

#tela-login h2 {
    color: #2E3092;
    text-transform: uppercase;
}

#tela-login-rh h2 {
    color: #2E3092;
    text-transform: uppercase;
}

#tela-login-area-inputs {
    margin-top: -80px;
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
}

#form-login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#botao-login-admin {
    height: 40px;
    width: 120px;
    color: white;
    background-color: #2E3092;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#botao-login-rh {
    height: 40px;
    width: 120px;
    color: white;
    background-color: #2E3092;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 90px;
}

#esqueci-minha-senha {
    color: #2E3092;
    border: none;
    background-color: gainsboro;
    font-size: 14px;
    cursor: pointer;
}

#tela-login input {
    height: 40px;
    width: 80%;
    margin: 10px;
    padding-left: 10px;
}

#container-datatable {
    padding-right: 400px;
    width: 100%;
    height: 400px;
    background-color: red;
}












@media only screen and (max-width: 1024px) {
    .menu li {
        font-size: 14px;
        width: 125px;
    }

    .info-dados h1 {
        font-size: 18px;
    }

    .info-dados {
        padding-right: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .logo img {
        padding: 20px;
    }
}

@media only screen and (max-width: 475px) {
    .info-topo {
        visibility: hidden;
    }

    .principal-topo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-top: 100px;
    }

    .menu {
        margin-top: -40px;
        box-shadow: 0;
    }

    .slides {
        box-shadow: 0;
        margin-top: 0px;
    }

    .linha {
        flex-wrap: wrap;
        margin-bottom: 200px;
    }

    .box-1c {
        width: 90%;
    }

    .box-newsletter {
        width: 90%;
    }

    .conteudo-footer {
        display: flex;
        flex-wrap: wrap;
    }

    .box-footer {
        width: 90%;
    }

    .box-footer h3 {
        font-size: 16px;
    }

    .lista-footer a {
        font-size: 12px;
    }
}


body {

    background-color: #f5f5f5;

}
